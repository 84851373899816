import React from "react";
import Image from "react-bootstrap/Image";

export type HeaderImageProps = {
  src: string;
  alt: string;
};

const HeaderImage = (props: HeaderImageProps) => {
  return (
    <Image src={props.src} className="shadow" alt={props.alt} fluid rounded />
  );
};

export default HeaderImage;
