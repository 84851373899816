import React from "react";
import Header from "../Common/Header";
import TournamentTable from "./TournamentTable";
import {Helmet} from "react-helmet";

export const Tournaments = () => {
    return (
      <div>
              <Helmet>
        <title>Waterside Archers - Tournaments</title>
        <meta name="description" content="Waterside Archers runs regular indoor and outdoor archery tournaments in the New Forest, Hampshire. Find the details and diary for the year here." />
      </Helmet>
        <Header text="Archery Tournaments" />
        <div className="container">
            <TournamentTable />
            <p>For details of all competitions in the UK please see the <a href="https://www.archerygb.org/shoot-compete/compete/find-a-tournament/tournaments-2021/" target="_new">archery GB website</a></p>
        </div>
      </div>
    );
  };
  

export default Tournaments;