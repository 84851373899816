import React from "react";
import Header from "../Common/Header";
import lone_target from "../images/lone_target.jpg";
import targets from "../images/targets.jpg";
import HeaderImage from "../Common/HeaderImage";
import {Helmet} from "react-helmet";

export const Membership = () => {
  return (
    <>
          <Helmet>
        <title>Waterside Archers - Membership</title>
        <meta name="description" content="Find out more about joining the Waterside Archers slub and meeting our committee and other members." />
      </Helmet>
      <Header text="Membership" />
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <HeaderImage src={targets} alt="targets" />
          </div>
          <div className="col-md-7 align-self-center">
            <div className="text-container">
              <p className="lead">
                If you are interested in joining Waterside Archers and you have
                passed a beginners course or have an Archery GB number then why
                not drop in and see the club.
              </p>

              <p className="lead">
                The best day is Sunday morning from 10am when we are normally
                shooting at our Exbury Range.
              </p>

              <p className="lead">
                You can come and join us at the field for £5.00 green fees
                Sunday 10am - 12pm. As a club we have indoor shooting over
                Winter months. We have a club field with exclusive club use,
                well maintained and open all day subject to AGB rules of
                shooting. If you wish for more details then please visit the{" "}
                <a href="/contact">contact page</a>
              </p>

              <p className="lead">
                To see our Committee members please{" "}
                <a href="files/Members/COMMITTEE_PHOTOBOARD.pdf">click here</a>{" "}
              </p>

              <p className="lead">
                For any safeguarding related issues please <a href="/contact">contact</a> our Safeguarding
                Officer.
              </p>
            </div>
          </div>
        </div>

        <hr />

        <div className="row flex-column-reverse flex-md-row">
          <div className="col-md-7 align-self-center">
            <h3>Membership Cost</h3>
            <p className="lead">
              Please <a href="/contact">contact</a> the secretary for the latest
              Club and Archery GB fees
            </p>
          </div>
          <div className="col-md-5 align-self-center">
            <HeaderImage src={lone_target} alt="target" />
          </div>
        </div>
      </div>
    </>
  );
};
