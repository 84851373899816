import React from "react";
import Header from "../Common/Header";
import { ClubDocsTable } from "./ClubDocsTable";
import {Helmet} from "react-helmet";

export const ClubDocs = () => {
  return (
    <div>
      <Helmet>
        <title>Waterside Archers - Club Policies, Procedures & Documents</title>
        <meta name="description" content="Waterside Archers Club Documents includes handy material in the Club Handbook as well as our policies and procedures for reference." />
      </Helmet>
      <Header text="Club Policies, Procedures & Docs" />
      <div className="container">
        <ClubDocsTable />
      </div>
    </div>
  );
};
