import React from "react";
import "./Map.css";

type MapProps = {
  location: string;
};

export const Map: React.FC<MapProps> = props => {
  return (
    <div className="mapouter">
      <iframe
        title="map"
        width="375"
        height="375"
        id="gmap_canvas"
        src={`https://maps.google.com/maps?q=${props.location}&t=&z=13&ie=UTF8&iwloc=&output=embed`}
        scrolling="no"
      ></iframe>
    </div>
  );
};
