import React from "react";
import { Locations } from "./Locations";
import Header from "../Common/Header";
import flag_image from "../images/flag.jpg";
import HeaderImage from "../Common/HeaderImage";
import {Helmet} from "react-helmet";

export const About: React.FC = () => {
  return (
    <div className="about">
      <Helmet>
        <title>Waterside Archers - About the Club</title>
        <meta name="description" content="Waterside Archers is a friendly archery club based on the edge of the New Forest in Hampshire. We welcome everyone from beginners to experienced archers." />
      </Helmet>
      <Header text="About the club" />
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <HeaderImage src={flag_image} alt="site" />
          </div>
          <div className="col-md-7 align-self-center">
            <div className="text-container">
              <p className="lead">
                Set on the South coast within the beautiful New Forest,
                Waterside Archers boasts an impressive and ever growing
                membership. Our archers embrace all traditions of shooting and
                bow style; from the instantly recognisable Longbow to the rather
                more modern Compound Bow and the bow of the Olympics - the
                Recurve, with Barebow in between.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Locations />
    </div>
  );
};
