import React from "react";

import Header from "../Common/Header";
import Button from "react-bootstrap/Button";
import { NewsletterTable } from "./NewsletterTable";

import flag_image from "../images/targets.jpg";
import HeaderImage from "../Common/HeaderImage";
import { Helmet } from "react-helmet";

export const News: React.FC = () => {

  return (
    <div className="news">

      <Helmet>
        <title>Waterside Archers - News</title>
        <meta name="description" content="The latest news from Waterside Archers." />
      </Helmet>
      <Header text="Latest News" />
      
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <HeaderImage src={flag_image} alt="site" />
          </div>
          <div className="col-md-7 align-self-center">
            <div className="text-container">
              <p className="lead">
                You can find the latest club news on our social accounts:</p>

                <Button className="m-2" variant="primary" href="https://www.facebook.com/pg/WatersideArchers/">Facebook <i
                  className="fab fa-facebook-square fa-2x"
                  style={{ color: "white" }}
                ></i>
                </Button>


                <Button className="m-2" variant="primary" href="https://www.instagram.com/waterside_archers/">Instagram <i
                  className="fab fa-instagram-square fa-2x"
                  style={{ color: "white" }}
                ></i></Button>
              
            </div>
          </div>
        </div>

        <div className="row pt-5">
          <div className="col-md-5">
            <p className="lead">
              We also publish a regular newsletter with club news, useful archery advice from the coaches,
              and other interesting content from our members.
            </p>
          </div>
          <div className="col-md-7">
            <div className="container">
              <NewsletterTable />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
