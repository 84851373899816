import React from "react";
import covid from "../images/Announcements/Covid-19.png";
import Header from "../Common/Header";
import Image from "react-bootstrap/Image";

export const Covid = () => {
  return (
    <div>
      <Header text="Covid-19" />
      <p>SATURDAY 16TH MAY 2020</p>
      <div className="container text-left">
        <p>
          The UK government announced the easing of some lockdown restrictions
          on Monday 11th May and Archery GB have been working with Sport England
          and the Department for Digital, Culture, Media & Sport (DCMS) since
          then to develop a phased plan for a return to archery activities in
          the UK. Archery GB have published the first phase of this guidance
          this evening:
        </p>
        <p>
          <a href="https://www.archerygb.org/return-to-archery-phase-one">
            https://www.archerygb.org/return-to-archery-phase-one
          </a>
        </p>
        <p>
          The club committee have held an online meeting this evening to discuss
          the guidance issued by Archery GB and to formulate a plan to re-open
          our facilities in accordance with phase one of this. There are a
          number of measures which will need to be put into place before we can
          resume shooting at the club, the committee will now work hard to
          achieve these as quickly and as safely as possible over the coming
          days or weeks. The guidelines may be unachievable for a number of
          clubs, who will therefore have to remain closed, but we feel confident
          that with the support of our members we will be able to open Waterside
          Archers Club again very soon.
        </p>
        <p>
          We are working hard towards Sunday 24th May, this may slip to Sunday
          31st if the measures are not in place in time. This will be
          communicated in due course
        </p>
        <p>
          Archery GB have released guidance not only for clubs and coaches, but
          also detailed information aimed at individual archers. It is
          imperative that you, as members of the club, should read this
          information and be aware of how shooting will resume and the ongoing
          conditions that will be in place during this phase. This guidance is
          available at:
        </p>
        <p>
          <a href="https://www.archerygb.org/wp-content/uploads/2020/05/Guidance-Notes-for-Archers-FINAL.pdf">
            https://www.archerygb.org/wp-content/uploads/2020/05/Guidance-Notes-for-Archers-FINAL.pdf
          </a>
        </p>
        <p>
          We understand that many of you will be keen to resume shooting, but
          please bear with us whilst we put the necessary measures in place.
          Most of the club committee are still working full-time from home and
          also juggling new realities of lockdown such as home schooling!
        </p>
        <p>
          Further to the government and AGB guidelines, please read the links
          above regarding how many people per target, family shooting etc, the
          following is by no means exhausted and will be added to as we find new
          challenges.
        </p>
        <ol>
          <li>
            Each target will be positioned in a 2m lane, it is to stay in the
            lane (we would discourage people from moving the target, if you
            must, please bring your own hammer as all targets will be pegged
            down)
          </li>
          <li>
            Each target will have an all-weather face (stock permitting) please
            do not remove.
          </li>
          <li>
            There will be a 3m gap/ no go zone between the targets, this will be
            marked with hatching on the shooting line giving the targets a 5m
            centre to centre distancing as per AGB guidelines.
          </li>
          <li>
            Hand sanitiser will be fixed to each target for your hands and
            potentially wipe down the face on completion of shooting
          </li>
          <li>
            If a person is not abiding to the Gov and AGB guidelines, the
            committee will ask that person to leave the field for the safety of
            the other members.
          </li>
          <li>
            Communal area, clubhouse, toilets will remain out of use unless an
            emergency
          </li>
        </ol>
        <p>
          Peter Parsons <br />
          Club Chairman
        </p>
        <div className="text-center">
          <Image
            src={covid}
            className="shadow"
            alt="Turn Up. Shoot. Go Home"
            fluid
          />
        </div>
      </div>
    </div>
  );
};
