import { useMemo } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';

import { RoundRecord, RecordKey } from './RecordsContext';

type RoundsTableProps = {
  data: RoundRecord[];
  isLoading: boolean;
  onRecordSelected?: (record:RecordKey) => void;
};

const RoundsTable = (props:RoundsTableProps) => {
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<RoundRecord>[]>(
    () => [
      {
        accessorKey: 'Round',
        header: 'Round',
        size: 100,
        enableHiding: false,
        enableSorting: true,
        filterVariant: 'select',
        filterFn: 'equalsString',
        footer: 'Round',
      },
      {
        accessorKey: 'Category',
        header: 'Category',
        size: 100,
        enableHiding: false,
        filterVariant: 'select',
        filterFn: 'equalsString',
        footer: 'Category',
      },
      {
        accessorKey: 'Recurve',
        header: 'RC',
        Header: ({ column }) => (
          <div title='Recurve'>{column.columnDef.header}</div>
        ),
        size: 50,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableSorting: false,
        enableGrouping: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        footer: 'RC',
      },
      {
        accessorKey: 'Barebow',
        header: 'BB',
        Header: ({ column }) => (
          <div title='Barebow'>{column.columnDef.header}</div>
        ),
        size: 50,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableSorting: false,
        enableGrouping: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        footer: 'BB',
      },
      {
        accessorKey: 'Longbow',
        header: 'LB',
        Header: ({ column }) => (
          <div title='Longbow'>{column.columnDef.header}</div>
        ),
        size: 50,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableSorting: false,
        enableGrouping: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        footer: 'LB',
      },
      {
        accessorKey: 'Compound',
        header: 'CU',
        Header: ({ column }) => (
          <div title='Compound'>{column.columnDef.header}</div>
        ),
        size: 50,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableSorting: false,
        enableGrouping: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        footer: 'C',
      },

    ],
    [],
  );



  const table = useMaterialReactTable({
    columns,
    data:props.data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableBottomToolbar: false,
    enableColumnDragging: false,
    enableDensityToggle: false,
    enableGrouping: true,
    enableFacetedValues: true,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,

    groupedColumnMode: 'remove',
    positionGlobalFilter: 'left',
    initialState: {
      density: 'compact',
      sorting: [{
        id: 'Round',
        desc: false,
      }],
      //grouping: ['Round'],
      pagination: { pageSize: 50, pageIndex: 0 },
      showGlobalFilter: true,
      showColumnFilters: true,
    },
    muiTableBodyProps: {
      sx: {
        //stripe the rows, make odd rows a darker color
        '& tr:nth-of-type(odd) > td': {
          backgroundColor: '#f5f5f5',
        },
        '& tr:not([data-selected="true"]):not([data-pinned="true"]):hover > td': {
          backgroundColor: '#ebebeb',
        },
      },
    },
    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        cursor: ["Recurve", "Barebow", "Compound", "Longbow"].includes(cell.column.id)?'pointer':'auto',
      },

      onClick: () => {
        if (["Recurve", "Barebow", "Compound", "Longbow"].includes(cell.column.id) &&
        cell.getValue() !== '') {
          if (props.onRecordSelected) {
            props.onRecordSelected({
              Round: props.data[Number.parseInt(cell.row.id)].Round,
              Class: cell.column.id,
              Category: props.data[Number.parseInt(cell.row.id)].Category
            });
          }
        }
      },
    }),
    state:{
      isLoading: props.isLoading,
    }

  });

  return (
      <MaterialReactTable table={table} />
  )
    ;
};

export default RoundsTable;
