import React from "react";
import Header from "../Common/Header";
import shooters_image from "../images/shooters.jpg";
import HeaderImage from "../Common/HeaderImage";
import {Helmet} from "react-helmet";

const Links = () => {
  return (
    <div className="courses">
            <Helmet>
        <title>Waterside Archers - Links</title>
        <meta name="description" content="Links to other Archery-related resources on the internet." />
      </Helmet>
      <Header text="Links" />
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <HeaderImage src={shooters_image} alt="shooters" />
          </div>

          <div className="col-md-4 align-top">
            <div className="text-container lead">
              <h2>Organisations</h2>
              <ul className="list-group text-left">
                <li className="list-group-item">
                  <span>Archery GB - </span>
                  <a href="https://www.archerygb.org/">www.archerygb.org</a>
                </li>
                <li className="list-group-item">
                  <span>FITA - </span>
                  <a href="http://www.archery.org/">www.archery.org</a>
                </li>
                <li className="list-group-item">
                  <span>SCAS (Southern Counties Archery Society) - </span>
                  <a href="https://scasarchery.org.uk/">
                    www.scasarchery.org.uk
                  </a>
                </li>
                <li className="list-group-item">
                  <span>HAA (Hampshire Archery Association) - </span>
                  <a href="https://haa.org.uk/">www.haa.org.uk</a>
                </li>
                <li className="list-group-item">
                  <span>NFAS (National Field Archery Sociery) - </span>
                  <a href="https://www.nfas.net/">www.nfas.net</a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 align-top">
            <div className="text-container lead">
              <h2>Guides</h2>
              <ul className="list-group text-left">
                <li className="list-group-item">
                  <a download href="files/Bow-Tuning-Tests.pdf">
                    Bow Tuning Tests
                  </a>
                  <span>
                    - A helpful guide on setting up and tuning your bow.
                  </span>
                </li>
                <li className="list-group-item">
                  <a href="https://www.bow-international.com/features/technique/recurve-setup-and-tune/">
                    Bow International
                  </a>
                  <span>- recurve setup.</span>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md-4 align-top">
            <div className="text-container lead">
              <h2>Shops</h2>
              <ul className="list-group text-left">
                <li className="list-group-item">
                  <span>Bowsports - </span>
                  <a href="https://www.bowsports.com/">www.bowsports.com</a>
                </li>
                <li className="list-group-item">
                  <span>Carol - </span>
                  <a href="http://www.carolarchery.com/">
                    www.carolarchery.com
                  </a>
                </li>
                <li className="list-group-item">
                  <span>Chiltern Archery - </span>
                  <a href="https://www.chiltern-archery.co.uk/">
                    www.chiltern-archery.co.uk
                  </a>
                </li>
                <li className="list-group-item">
                  <span>Merlin - </span>
                  <a href="https://www.merlinarchery.co.uk/">
                    www.merlinarchery.co.uk
                  </a>
                </li>
                <li className="list-group-item">
                  <span>Quicks - </span>
                  <a href="https://www.quicksarchery.co.uk/">
                    www.quicksarchery.co.uk
                  </a>
                </li>
                <li className="list-group-item">
                  <span>The Longbow Shop - </span>
                  <a href="https://www.thelongbowshop.com/">
                    www.thelongbowshop.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Links;
