import { useEffect, useState } from "react";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import RecordsTable from "./RecordsTable";
import RoundsTable from "./RoundsTable";
import { RoundRecord, ClubRecord , RecordKey} from './RecordsContext';


const RecordsTabs = () => {
    const [roundsData, setRoundsData] = useState([] as RoundRecord[]);
    const [clubData, setClubData] = useState([] as ClubRecord[]);
    const [hasErrored, setHasErrored] = useState(false);
    const [loading, setLoading] = useState(false);
    const [currentRecord, setCurrentRecord] = useState({Round:"", Class:"", Category:""} as RecordKey);
    const [currentTab, setCurrentTab] = useState("rounds");

    useEffect(() => {
        const loadRecords = async () => {
            setLoading(true);
            try {
                const response = await fetch(
                    `/.netlify/functions/records`
                );

                const records = await response.json();

                setRoundsData(records.roundsData);

                setClubData((records.clubData as ClubRecord []).map((record) => {
                    return ({ ...record, Date: new Date(record.Date) });
                }));

            } catch {
                console.log("Error happened processing records")
                setHasErrored(true);
            } finally {
                setLoading(false);
            }
        };
        loadRecords();
    }, []);

    return (
        <div>
            <Tabs defaultActiveKey="rounds"
                activeKey={currentTab}
                onSelect={(key) => { 
                    setCurrentTab(key as string)
                }}
                unmountOnExit={true}
                mountOnEnter={true}
                transition={false}
                id="uncontrolled-tab-example">
                <Tab eventKey="rounds" title="Rounds">
                    <RoundsTable data={roundsData} isLoading={loading}
                        onRecordSelected={
                            (record: RecordKey) => {
                                setCurrentRecord(record);
                                setCurrentTab(record.Class.toLowerCase());
                            }}
                    />
                </Tab>
                <Tab eventKey="recurve" title="Recurve">
                    <RecordsTable sheet="Recurve" data={clubData} isLoading={loading} currentRecord={currentRecord}/>
                </Tab>
                <Tab eventKey="barebow" title="Barebow">
                    <RecordsTable sheet="Barebow" data={clubData} isLoading={loading} currentRecord={currentRecord}/>
                </Tab>
                <Tab eventKey="longbow" title="Longbow">
                    <RecordsTable sheet="Longbow" data={clubData} isLoading={loading} currentRecord={currentRecord}/>
                </Tab>
                <Tab eventKey="compound" title="Compound">
                    <RecordsTable sheet="Compound" data={clubData} isLoading={loading} currentRecord={currentRecord}/>
                </Tab>    </Tabs>
            {hasErrored && <p>There was an error loading the data</p>}
        </div>
    );
};

export default RecordsTabs;