import React from "react";
import Header from "../Common/Header";
import CoursesTable from "./CoursesTable";
import AfterBeginner from "./AfterBeginner";
import HeaderImage from "../Common/HeaderImage";
import {Helmet} from "react-helmet";

export const Courses = () => {
  return (
    <div className="courses">
            <Helmet>
        <title>Waterside Archers - Beginners Courses</title>
        <meta name="description" content="Waterside Archers runs regular beginners archery courses at our shooting ground on edge of the New Forest in Hampshire." />
      </Helmet>
      <Header text="Archery Courses" />
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <a href="files/BEGINNERS_COURSES_Flyer_2024_v1.pdf">
              <HeaderImage src={"files/BEGINNERS COURSES Flyer 2024 v1.png"} alt="2024 Beginners courses poster" />
            </a>
          </div>
          <div className="col-md-7 align-self-center">
            <h2>Current Archery Courses</h2>
            <CoursesTable />
          </div>
        </div>

        <hr />
        <AfterBeginner />
      </div>
    </div>
  );
};
