import React from "react";

interface BeginnerInstructionsProps {
  signupUrl: string;
  faqUrl: string;
}

const BeginnerInstructions = (props: BeginnerInstructionsProps) => {
  return (
    <div>
      <p className="lead">
        <span className="h5">Important Note</span>
        <br />
        The course normally runs over 3 consecutive Saturdays, please double
        check the dates before booking.
      </p>

      <p className="lead">
        <span className="h5">Dress code for the courses</span>
        <br />
        Closed toes shoes, plus appropriate weather gear (we do shoot in rain
        but if the weather is too bad we may move sessions. Communication will
        be sent to let you know if a course may be affected.)
      </p>

      <hr />

      {props.signupUrl && (
        <a
          className="btn btn-primary font-weight-bold text-white mb-2"
          download
          href={props.signupUrl}
        >
          <i className="fas fa-file-download fa-2x text-white"></i> &nbsp;
          Please click here for the signup form
        </a>
      )}
      <br />
      {props.faqUrl && (
        <a
          className="btn btn-primary font-weight-bold text-white"
          download
          href={props.faqUrl}
        >
          <i className="fas fa-file-download fa-2x text-white"></i> &nbsp;
          Please click here for the FAQ
        </a>
      )}

      <p className="font-weight-light mt-2">
        Any questions please contact the secratary via the{" "}
        <a href="/contact">contact page.</a>
      </p>
    </div>
  );
};

export default BeginnerInstructions;
