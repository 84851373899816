import React from "react";

const Footer = () => {
  return (
    <footer className="pt-5 text-muted text-center text-small">
      <p className="mb-1">
        {/* Site created by <a href="http://www.pragward.co.uk">Pagward</a> */}
      </p>
    </footer>
  );
};

export default Footer;
