import "react-app-polyfill/ie11";
// import React from "react";
// import ReactDOM from 'react-dom/client';
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./style/waterside.css";
import { hydrate, render } from "react-dom";

//const root = ReactDOM.createRoot(document.getElementById('root') as Element);

const rootElement = document.getElementById("root");

//root.render(<App />);

if (rootElement && rootElement.hasChildNodes()) {
    hydrate(<App />, rootElement);
  } else {
    render(<App />, rootElement);
  }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
