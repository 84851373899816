import React from "react";
import "./Header.css";

type HeaderProps = {
  text: string;
};

const Header = (props: HeaderProps) => {
  return <h1 className=" header display-3">{props.text}</h1>;
};

export default Header;
