import { Helmet } from "react-helmet";

export default function NotFound() {
    return (
        <div>
            <Helmet>
                <title>Waterside Archers - 404 Error</title>
                <meta name="description" content="Missing page" />
            </Helmet>
            <h1>Oops! You seem to be lost.</h1>
            <p>Please use the menu at the top to find what you are looking for.</p>
        </div>
    )
}