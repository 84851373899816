import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";

interface TournamentData {
  title: string;
  date: string;
  file1Title: string;
  file1Url: string;
  file2Title: string;
  file2Url: string;
}

const TournamentTable = () => {
  const [data, setData] = useState([] as TournamentData[]);
  const [hasErrored, setHasErrored] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadRecords = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `/.netlify/functions/data?key=tournaments`
        );
        const json = await response.json();
        setData(json);
      } catch {
        setHasErrored(true);
      } finally {
        setLoading(false);
      }
    };
    loadRecords();
  }, []);

  return (
    <div className="row">
      <div className="col-md-12 align-self-center">
        <div className="text-container">
          <Table size="sm" striped>
            <thead>
              <tr>
                <th
                  className="text-uppercase font-weight-light text-left"
                  scope="col"
                >
                  Tournament
                </th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              {data.map((r) => (
                <tr key={r.title}>
                  <th className="text-left" scope="row">
                    {r.title}
                    <br />
                    <small>{r.date}</small>
                  </th>
                  <td className="align-middle text-right">
                  {r.file1Title && (
                    <a
                      className="btn btn-primary"
                      target="_new"
                      href={r.file1Url}
                    >
                      <i className="fas fa-download"></i> {r.file1Title}
                    </a>
                  )}
                    {r.file2Title && (
                      <a
                        className="btn btn-primary"
                        target="_new"
                        href={r.file2Url}
                      >
                        <i className="fas fa-download"></i> {r.file2Title}
                      </a>
                    )}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {loading && <i className="fas fa-spinner fa-3x fa-spin"></i>}
          {hasErrored && <p>There was an error loading the data</p>}
        </div>
      </div>
    </div>
  );
};

export default TournamentTable;
