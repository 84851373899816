import React, { ReactElement } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export interface ModalCloseFunc {
  (): void;
}
export type InformationModalProps = {
  show: boolean;
  handleClose: ModalCloseFunc;
  title: string;
  body: ReactElement;
};

const InformationModal = (props: InformationModalProps) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg" centered>
      <Modal.Header closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{props.body}</Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={props.handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

InformationModal.defaultProps = {
  title: "Modal title x",
  body: ""
};

export default InformationModal;
