import React from "react";
import "./App.css";
import Menu from "./Menu";
import { BrowserRouter } from "react-router-dom";
import Footer from "./Footer";
import { Helmet } from "react-helmet";

const App: React.FC = () => {
  return (
    <div className="App">
      <Helmet>
        <title>Waterside Archers</title>

        <script type="application/ld+json">
          {JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            "name": "Waterside Archers",
            "url": "https://watersidearchers.co.uk/",
          })}
        </script>

      </Helmet>
      <Menu />
      <BrowserRouter></BrowserRouter>
      <Footer></Footer>
    </div>
  );
};

export default App;
