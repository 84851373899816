import { useMemo, useState } from 'react';
import {
  MaterialReactTable,
  useMaterialReactTable,
  type MRT_ColumnDef,
} from 'material-react-table';
import { Box, Typography } from '@mui/material';

import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { ClubRecord, RecordKey } from './RecordsContext';

type RecordTableProps = {
  sheet: string;
  data: ClubRecord[];
  isLoading: boolean;
  currentRecord: RecordKey;
};

const RecordsTable = (props:RecordTableProps) => {
  const [filteredRecord, setFilteredRecord] = useState({Round:"", Class:"", Category:""} as RecordKey);
  
  //should be memoized or stable
  const columns = useMemo<MRT_ColumnDef<ClubRecord>[]>(
    () => [
      {
        accessorKey: 'Round',
        header: 'Round',
        size: 100,
        enableHiding: false,
        enableSorting: true,
        filterVariant: 'select',
        filterFn: 'equalsString',
        footer: 'Round',
      },
      {
        accessorKey: 'Class',
        header: 'Class',
        size: 100,
        enableHiding: false,
        filterVariant: 'select',
        filterFn: 'equalsString',
      },      
      {
        accessorKey: 'Category',
        header: 'Category',
        size: 100,
        enableHiding: false,
        filterVariant: 'select',
        filterFn: 'equalsString',
        footer: 'Category',
      },
      {
        accessorKey: 'Archer',
        header: 'Archer',
        size: 100,
        enableHiding: false,
        filterVariant: 'select',
        filterFn: 'equalsString',
        footer: 'Archer',
      },
      {
        accessorKey: 'Date',
        header: 'Date',
        Cell: ({ cell }) => (
          <span>{cell.getValue<Date>().toLocaleDateString()}</span>
        ),
        size: 100,
        enableHiding: false,
        enableColumnFilter: false
      },
      {
        accessorKey: 'Score',
        header: 'Score',
        size: 50,
        muiTableHeadCellProps: {
          align: 'center',
        },
        muiTableBodyCellProps: {
          align: 'center',
        },
        enableSorting: false,
        enableGrouping: false,
        enableColumnFilter: false,
        enableColumnActions: false,
        footer: 'Score',
      },
    ],
    [],
  );
  
  const table = useMaterialReactTable({
    columns,
    data:props.data, //data must be memoized or stable (useState, useMemo, defined outside of this component, etc.)
    enableBottomToolbar: false,
    enableColumnDragging: false,
    enableDensityToggle: false,
    enableGrouping: true,
    enableFacetedValues: true,
    enablePagination: false,
    enableStickyHeader: true,
    enableStickyFooter: true,
    enableHiding: false,

    groupedColumnMode: 'remove',
    positionGlobalFilter: 'left',
    initialState: {
      density: 'compact',
      columnFilters:
        [{
          id: 'Class',
          value: props.sheet,
        }
        ],
      sorting: [{
        id: 'Date',
        desc: true,
      }],
      columnVisibility: {'Class': false},
      //grouping: ['Round'],
      pagination: { pageSize: 50, pageIndex: 0 },
      showGlobalFilter: true,
      showColumnFilters: true,
    },
    localization: MRT_Localization_EN,
    renderDetailPanel: ({ row }) => (
      <Box
                sx={{
                  display: 'grid',
                  margin: 'auto',
                  gridTemplateColumns: '1fr 1fr',
                  width: '100%',
                }}>
            <Typography>Score: {row.original.Score}</Typography>
            <Typography>Hits: {row.original.Hits}</Typography>
            <Typography>Golds: {row.original.Golds}</Typography>
            <Typography>Xs: {row.original.Xs}</Typography>
            <Typography>Location: {row.original.Location}</Typography>
          </Box>
        ),
        displayColumnDefOptions: {
          'mrt-row-expand': {
            size: 30, //make the expand column wider
          },
        },
        muiTableBodyProps: {
          sx: {
            //stripe the rows, make odd rows a darker color
            '& tr:nth-of-type(4n+1) > td': {
              backgroundColor: '#f5f5f5',
            },
          },
        },
        state:{
          isLoading: props.isLoading,
        },
  });

  if (props.sheet === props.currentRecord.Class &&
    props.currentRecord !== filteredRecord) {
    setFilteredRecord(props.currentRecord);

    table.setColumnFilters([
      {
        id: 'Round',
        value: props.currentRecord.Round,
      },
      {
        id: 'Class',
        value: props.sheet,
      },
      {
        id: 'Category',
        value: props.currentRecord.Category,
      }
    ]);
  }

  return (
      <MaterialReactTable table={table} />
  )
    ;
};

export default RecordsTable;
