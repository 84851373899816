import React from "react";
import bullseye from "../images/bullseye.jpg";
import pete from "../images/pete.jpg";
import HeaderImage from "../Common/HeaderImage";

const AfterBeginner = () => {
  return (
    <>
      <div className="row flex-column-reverse flex-md-row">
        <div className="col-md-7 align-self-center">
          <h3>Equipment</h3>
          <p className="lead">
            While doing the course you would have used the club equipment. This
            can continue after you have finished. We do encourage you to get
            your own equipment when you are ready to commit and you have got
            past the initial growth of technique and want to move onto better
            equipment. Coaches are available to advise on your purchase.
          </p>
        </div>
        <div className="col-md-5">
          <HeaderImage src={bullseye} alt="bullseye" />
        </div>
      </div>

      <hr />

      <div className="row">
        <div className="col-md-5">
          <HeaderImage src={pete} alt="pete" />
        </div>
        <div className="col-md-7 align-self-center">
          <h3>Conditions of club equipment usage</h3>
          <ol className="text-left lead">
            <li>Beginners courses take precedence</li>
            <li>
              You may find you are sharing with others so availability may be
              limited
            </li>
            <li>
              Access to equipment will only be available when a key holder is
              present
            </li>
          </ol>
        </div>
      </div>
    </>
  );
};

export default AfterBeginner;
