import React, { useEffect, useState } from "react";
import Table from "react-bootstrap/Table";

interface DocsData {
  name: string;
  link1Title: string;
  link1Url: string;
  link2Title: string;
  link2Url: string;
}

export interface ScoresheetTableProps {
  id: string;
  title: string;
}

export const ScoresheetsTable = (props: ScoresheetTableProps) => {
  const [data, setData] = useState([] as DocsData[]);
  const [hasErrored, setHasErrored] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const loadRecords = async () => {
      setLoading(true);

      try {
        const response = await fetch(`/.netlify/functions/data?key=`+props.id);
        
        const json = await response.json();
        setData(json);
      } catch {
        setHasErrored(true);
      } finally {
        setLoading(false);
      }
    };
    loadRecords();
  }, [props.id]);

  return (
    <div>


      <div className="row">
        <div className="col-md-12 align-self-center">
          <div className="text-container">
            <Table size="sm" striped>
              <thead>
                <tr>
                  <th
                    className="text-uppercase font-weight-light text-left"
                    scope="col"
                    colSpan={2}
                  >
                    {props.title}
                  </th>

                </tr>
              </thead>
              <tbody>
                {data.map((r) => (
                  <tr key={r.name}>
                    <th className="align-middle text-left" scope="row">
                      {r.name}
                    </th>
                    <td className="align-middle text-left d-sm-table-cell">
                      <a
                        className="btn btn-primary font-weight-bold text-white"
                        target="_new"
                        href={r.link1Url}
                      >
                        <i className="fas fa-file-download fa-2x text-white"></i>{" "}
                        &nbsp; {r.link1Title}
                      </a>
                      {r.link2Title && r.link2Url && (
                        <a
                          className="btn btn-primary font-weight-bold text-white"
                          download
                          href={r.link2Url}
                        >
                          <i className="fas fa-file-download fa-2x text-white"></i>{" "}
                          &nbsp; {r.link2Title}
                        </a>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {loading && <i className="fas fa-spinner fa-3x fa-spin"></i>}
            {hasErrored && <p>There was an error loading the data</p>}
          </div>
        </div>
      </div>
    </div>
  );
};
