import React from "react";
import Carousel from "./Carousel";
import easyFundraising from "../images/EasyFundraising.jpg";
import {Helmet} from "react-helmet";

export const Home: React.FC = () => {
  return (
    <div>
      <Helmet>
        <title>Waterside Archers - Welcome</title>
        <meta name="description" content="Waterside Archers is a friendly archery club based on the edge of the New Forest in Hampshire." />
      </Helmet>
      <Carousel />
      <p className="font-weight-light">
        Make purchases using the link below and support your club
      </p>
      <a href="https://www.easyfundraising.org.uk/causes/watersidearchers/">
        <img className="w-25" src={easyFundraising} alt="Easy Fundraising" />
      </a>
    </div>
  );
};
