import React from "react";
import "./CarouselStyle.css";
import cover1 from "../images/Cover/Waterside_Cover1.webp";
import cover1_300 from "../images/Cover/Waterside_Cover1-300.webp";
import cover1_768 from "../images/Cover/Waterside_Cover1-768.webp";
import cover1_1280 from "../images/Cover/Waterside_Cover1-1280.webp";
import cover2 from "../images/Cover/Waterside_Cover2.webp";
import cover3 from "../images/Cover/Waterside_Cover3.webp";
import logo from "../images/logo/Logo.png";
import Carousel from "react-bootstrap/Carousel";

const WatersideCarousel: React.FC = () => {
  return (
    <Carousel onSelect={(key) => {

      // todo make it specific to this component, not the doc
      const elem = document.getElementsByClassName('carousel-item')[key]

      var lazy;
      lazy = elem.querySelector("img") as Element;
      if (lazy.hasAttribute("data-src")) {
        lazy.setAttribute("src", lazy.getAttribute("data-src") as string);
        lazy.removeAttribute("data-src");
      }
    }}>
      <Carousel.Item>
        <img className="d-block w-100"
          src={cover1} alt=""
          srcSet={cover1_300+" 300w, "+cover1_768+" 768w, "+cover1_1280+" 1280w"}
          sizes="(max-width: 300px) 300px, (max-width: 768px) 768px, 1280px"
        />
        <div className="tint"></div>
        <Carousel.Caption className="carousel-caption-top">
          <h1 className="d-md-block">
            Welcome to Waterside Archers
          </h1>
          <p className="d-none d-sm-none d-md-block">
            The South's Premier Archery Club
          </p>
          <img src={logo} alt="logo" />
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" data-src={cover2} alt="" />
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" data-src={cover3} alt="" />
      </Carousel.Item>
    </Carousel>
  );
};

export default WatersideCarousel;
