import React, { useState } from "react";
import Header from "../Common/Header";
import single_target from "../images/single_target.jpg";
import HeaderImage from "../Common/HeaderImage";
import ContactForm from "./ContactForm";
import {Helmet} from "react-helmet";

const Contact = () => {
  const [sentEmail, setSentEmail] = useState(false);
  const [emailError, setEmailError] = useState(false);

  const handleEmailSent = (success: boolean) => {
    setSentEmail(success);
    setEmailError(!success);
  };

  return (
    <>
      <Helmet>
        <title>Waterside Archers - Contact Us</title>
        <meta name="description" content="Send a message to the friendly Waterside Archers committee members." />
      </Helmet>
      <Header text="Contact" />
      <div className="container">
        <div className="row">
          <div className="col-md-5">
            <HeaderImage src={single_target} alt="target" />
          </div>
          <div className="col-md-7 align-self-center">
            <h2>Contact Us</h2>
            {!sentEmail && !emailError && (
              <ContactForm emailSent={handleEmailSent} />
            )}

            {sentEmail && (
              <p className="lead">
                Thank you for your email, we will be in contact soon.
              </p>
            )}

            {emailError && (
              <p className="lead">
                Sorry, there is a problem with our contact form. Please try
                again soon.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
